import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/src/app/ui/button.tsx");
import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/src/components/common/content-box.tsx");
import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/src/components/common/partner-carousel.tsx");
import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/src/components/home/crm-left.tsx");
import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/src/components/home/crm-right.tsx");
import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/src/components/home/customer-says.tsx");
import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/src/components/home/delevery-partners-two.tsx");
import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/src/components/home/index-panner.tsx");
import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/src/components/home/Insights.tsx");
import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/src/components/home/Partners.tsx");
import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/src/components/home/planning-banner.tsx");
import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/src/components/home/testi-home.tsx");
import(/* webpackMode: "eager" */ "/opt/app/invenzolabs-web/src/ui/container.tsx")