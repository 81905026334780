import Button from '@/app/ui/button'
import { getIcon } from '@/lib/get-icon'
import Input from '@/ui/input'
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useEffect, useMemo, useState, } from 'react'
import commonIcons from '../icons/common-icons'
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify'
import { useBookDemoMutation } from '@/framework/rest/contact/contact.query'
import { useRouter } from 'next/navigation';
import { ROUTES } from '@/utils/routes'
import Link from 'next/link'
import countryList from 'react-select-country-list'
import ReactCountryFlag from 'react-country-flag'


const contactSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().required("Email is required").email("Email is not valid"),
    phone: yup.number().required("Phone is required").typeError("Phone must be a number"),
    company_name: yup.string().required("company name is required"),
    industry: yup.string().required("Industry name is required"),
    country: yup.string().required("country name is required"),
})



export default function RequestDemo({ isRequestOpen, setIsOpen, closeRequestModal }: any) {
    const router = useRouter();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({ resolver: yupResolver(contactSchema) });
    const { mutate: contact, isIdle: isLoading } = useBookDemoMutation();

    const [enabled, setEnabled] = useState(true)
    const [token, setTokaen] = useState("");




    const [value, setValue] = useState('');   
    const values = value.split(',');

    const options: any = useMemo(() => countryList().getData(), [])
    const changeHandler = (e: any) => {
        setValue(e.target.value)
        setSelectCountry({ label: e.target.label, value: e.target.value })
    }
    
    var [selectCountry, setSelectCountry] = useState<{ label: string, value: string }>({ label: '', value: '' });
    
    const onSubmit = (data: any) => {
        // const token = captchaRef.current.getValue();
        contact({ ...data, phone: data?.phone.toString(), page_type:"book_a_demo",  token: token }, {
            onSuccess: (d) => {
                //toast.success(d?.data?.message);
                toast.success("Demo request sent successfully");
                router.push("/")
                reset();
                //setToken("")
                setEnabled(true)
                closeRequestModal()
            },
            onError: (error: any) => {
                toast.success(error?.response?.data?.message);
            }
        });
    };

    return (
        <div className="fixed top-0 w-full max-w-sm px-4 z-50">
            <Transition appear show={isRequestOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeRequestModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="w-5/12 xs:w-full sm:w-3/4 xl:w-4/12 absolute right-0 top-0 h-screen bg-white items-center justify-center p-0 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full h-full transform overflow-y-auto p-12 xs:p-6 lg:p-10 text-left align-middle transition-all">
                                    <span className='absolute border xs:flex xs:items-center border-black rounded-full p-3 xs:p-2 right-2 top-2 xs:top-2 xs:leading-3 xs:w-8 xs:h-8 cursor-pointer' onClick={closeRequestModal}>{getIcon({ iconList: commonIcons, iconName: 'CloseIcon', className: 'w-full stroke-black' })}</span>
                                    <Dialog.Title as="h3" className="text-black xs:w-[95%] text-[50px] xs:text-2xl sm:text-4xl lg:leading-[62px] font-semibold leading-[78px]">
                                        See the benefits of Invenzo's Retail SaaS in action
                                    </Dialog.Title>
                                    <div>
                                        <form className="" onSubmit={handleSubmit(onSubmit)}>
                                            <div className='flex xs:flex-wrap gap-5 my-6 xs:my-4'>
                                                <Input placeholder='First Name' className='w-full bg-[#F6F6F6] rounded' {...register("name")} errorKey={errors.name?.message} />
                                                <Input name="last_name" placeholder='Last Name' className='w-full bg-[#F6F6F6] rounded' />
                                            </div>

                                            <div className='flex xs:flex-wrap gap-5 my-6 xs:my-4'>
                                                <Input placeholder='Work Email' className='w-full bg-[#F6F6F6] rounded' {...register("email")} errorKey={errors.email?.message} />
                                                <Input placeholder='Phone number' className='w-full bg-[#F6F6F6] rounded' maxLength={10} {...register("phone")} pattern="[1-9]{1}[0-9]{9}" errorKey={errors.phone?.message} />
                                            </div>

                                            <div className='flex gap-5 my-6  xs:my-4'>
                                                <Input placeholder='Company Name' className='w-full bg-[#F6F6F6] rounded' {...register("company_name")} errorKey={errors.company_name?.message} />
                                                <Input placeholder='Industry' className='w-full bg-[#F6F6F6] rounded' {...register("industry")} errorKey={errors.industry?.message} />
                                            </div>

                                            <div className='my-6 xs:my-4'>

                                                {/* <CountrySelector {...register("country")} /> */}
                                                {/* <input {...register("country")} value={selectCountry?.label} /> */}

                                                {/* <Select {...register("country")} 
                                                options={options} 
                                                value={value} 
                                                onChange={changeHandler} />
                                                 */}
                                                {/* <Select {...register("country")} 
                                                    // placeholder="Country (Please Select)"
                                                    placeholder="India"
                                                    isSearchable={true}
                                                    options={options}
                                                    value={value}
                                                    //value={value}
                                                    onChange={changeHandler}
                                               //     styles={selectStyle}
                                                    className='border-0'
                                                /> */}

                                                <div className='flex items-center w-full border rounded-md bg-[#F6F6F6] py-1 pl-2'>
                                                    <div id="countryFlag" className='w-8 h-8 border-r '>
                                                        <ReactCountryFlag
                                                            countryCode={values?.[0] !== "" ? values?.[0] : 'in'}
                                                            svg
                                                            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                            cdnSuffix="svg"
                                                            title={values ? values?.[1] : ""}
                                                            className='bg-gray-200 !h-6 !w-6 !border-0'
                                                        />
                                                    </div>

                                                    <select {...register('country')} className='h-10 w-[93%] ' defaultValue='' onChange={(e:any)=>changeHandler(e)}>
                                                        <option value={""}>- Please Select country -</option>
                                                        {options?.map((option: any, index:number) => <option key={index + option?.lable} value={[option?.value, option?.label]}>{option?.label}</option>)}
                                                    </select>
                                                </div>



                                            </div>

                                            <div className="my-6">
                                                <p className="text-sm text- text-gray-500">
                                                    By completing this form, I agree that Invenzo labs may keep me informed of its products, services and offerings. To view our privacy policy, <Link className='text-blue-700' href={ROUTES?.PRIVACYPOLICY}>click here</Link>.
                                                </p>
                                            </div>
                                            <div className='text-right w-full'>
                                                <Button className='w-full' type="submit">Book a Demo</Button>
                                            </div>
                                        </form>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    )
}
