export const TitleStarIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none" {...props}>
            <path id="Star7" d="M24.9177 0.277344L30.0794 12.3397L42.2586 7.46016L37.379 19.6394L49.4414 24.801L37.379 29.9627L42.2586 42.1419L30.0794 37.2623L24.9177 49.3247L19.7561 37.2623L7.57686 42.1419L12.4564 29.9627L0.394043 24.801L12.4564 19.6394L7.57686 7.46016L19.7561 12.3397L24.9177 0.277344Z" fill="currentcolor" {...props}/>
        </svg>

    )
}
export const ButtonArrowRight: React.FC<React.SVGAttributes<{}>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
            <path d="M3.57178 11.0693H19.5718" stroke="white" strokeWidth="2" {...props}/>
            <path d="M11.5718 19.0693L19.5718 11.0693L11.5718 3.06934" stroke="white" strokeWidth="2" {...props}/>
        </svg>
    )
}
export const ButtonArrowRightTop: React.FC<React.SVGAttributes<{}>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
  <path d="M0.737793 13.1264L12.2472 2.0119M12.2472 2.0119L12.0498 13.3239M12.2472 2.0119L0.935245 1.81445" stroke="#1D1D1D" strokeWidth="2" {...props}/>
</svg>
    )
}

export default {
    TitleStarIcon,
    ButtonArrowRight,
    ButtonArrowRightTop
}