'use client'
import { getIcon } from '@/lib/get-icon'
import Container from '@/ui/container'
import React from 'react'
import bannerIcons from '../icons/banner-icons'
import SectionHeading from '../common/section-heading'
import useYscroll from '../common/functions'

export default function CrmRight({ props, titleClass }: any) {
  const { shown } = useYscroll({})
  return (
    <>
      {shown &&
        <Container>
          <SectionHeading title={props?.title} titleClass={titleClass} className="!w-full !px-0" />
          <div className="text-left text-stone-900 text-lg xs:text-base font-medium leading-7 tracking-wide mt-4">{props?.description}</div>

          <ul className="flex flex-col gap-5 mt-5">
            {props?.lists?.map((list: any, index: any) => (
              <li key={index + 'Crm List'} className="flex gap-4">
                <div className="mt-3" >{getIcon({ iconList: bannerIcons, iconName: 'ExploreIcon' })} </div>
                <div className="flex flex-col">
                  <h3 className="text-[26px] xs:text-lg font-semibold opacity-90">{list?.list}</h3>
                  <ul className="text-lg font-medium text=[#1C1B1B] xs:text-base" dangerouslySetInnerHTML={{ __html: list?.subText }}></ul>
                </div>
              </li>
            ))}
            {/* <li className="flex gap-4">
        <div className="mt-3" >{getIcon({ iconList: bannerIcons, iconName: 'ExploreIcon' })} </div>
        <div className="flex flex-col">
          <h3 className="text-[32px] font-semibold">Store Connect</h3>
          <p className="text-lg">Hyperlocal fulfillment | Store operations | Last Mile delivery.</p>
        </div>
      </li>
      <li className="flex gap-4">
        <div className="mt-3" >{getIcon({ iconList: bannerIcons, iconName: 'ExploreIcon' })} </div>
        <div className="flex flex-col">
          <h3 className="text-[32px] font-semibold">Multichannel Order Management</h3>
          <p className="text-lg">Advanced Inventory & Order Routing Engine | Pre Built Integrations | Reconciliation.</p>
        </div>
      </li>
      <li className="flex gap-4">
        <div className="mt-3" >{getIcon({ iconList: bannerIcons, iconName: 'ExploreIcon' })} </div>
        <div className="flex flex-col">
          <h3 className="text-[32px] font-semibold">Warehouse/Store Inventory management</h3>
          <p className="text-lg">Picking | Packing |Shipping | Inbound | Outbound.</p>
        </div>
      </li>
      <li className="flex gap-4">
        <div className="mt-3" >{getIcon({ iconList: bannerIcons, iconName: 'ExploreIcon' })} </div>
        <div className="flex flex-col">
          <h3 className="text-[32px] font-semibold">Delivery / Payment Partner Management </h3>
          <p className="text-lg">Geo Coding| Intelligent routing | Route Planning | Hyperlocal | Courier Partner.</p>
        </div>
      </li> */}

          </ul>
        </Container>
      }</>

  )
}
