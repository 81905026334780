'use client'
import Image from 'next/image';
import React from 'react'

export default function DeleveryPartnersTwo({ data }: any) {
    return (
        <div>
            <div className='mb-5'>
                <div className='font-semibold text-base underline underline-offset-4 decoration-2 mb-5 text-center decoration-[#C06CCA] uppercase'>{data?.[3]?.name}</div>
                <ul className='flex flex-wrap justify-center gap-5'>
                    {data?.[3]?.icons?.map((item: any, id:number) => (
                        <li key={id +  data?.[3]?.name}>
                            <Image src={`/images/home/delevery-partners/${data?.[3]?.path}/${item?.img}`} alt='' width={92} height={92} />
                        </li>
                    ))}
                </ul>
            </div>
            <div >
                <div className='font-semibold text-base underline underline-offset-4 decoration-2 mb-5 text-center decoration-[#C06CCA] uppercase'>{data?.[4]?.name}</div>
                <ul className='flex flex-wrap justify-center gap-5'>
                    {data?.[4]?.icons?.map((item: any, id:number) => (
                        <li key={id +  data?.[4]?.name}>
                            <Image src={`/images/home/delevery-partners/${data?.[4]?.path}/${item?.img}`} alt='' width={92} height={92} />
                        </li>
                    ))}
                </ul>
            </div>
            <div >
                <div className='font-semibold text-base underline underline-offset-4 decoration-2 mb-5s text-center decoration-[#C06CCA] uppercase'>{data?.[5]?.name}</div>
                <ul className='flex flex-wrap justify-center gap-5'>
                    {data?.[5]?.icons?.map((item: any, id:number) => (
                        <li key={id +  data?.[5]?.name}>
                            <Image src={`/images/home/delevery-partners/${data?.[5]?.path}/${item?.img}`} alt='' width={92} height={92} />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
