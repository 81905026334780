export default function YouTubeVideo({isVisible, onClose, children}:any) {

    if( !isVisible) return null 

   
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex h-screen justify-center items-center backdrop-blur-sm z-50">

      <div className="flex flex-col">
      <button onClick={onClose} className="text-xl text-white place-self-end">X</button>
      <div className="h-[170px] sm:w-[620px] lg:w-[800px] sm:h-[350px] lg:h-[450px]  bg-white">{children}</div>
      </div>
    </div>
  )
}
