"use client"
import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image';
import { getIcon } from '@/lib/get-icon';
import commonIcons from '../icons/common-icons';
import { useScroll, useSpring, useTransform } from 'framer-motion';
import Carousel from '@/ui/carousel/carousel';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import { Grid, Pagination } from 'swiper/modules';
import useYscroll from '../common/functions';


function Testimonials({ title }: any) {

    const testimonial = [
        {
            "id": 1,
            "designation": "AVP, eCommerce Operation",
            "name": "Dipayan Gupta",
            "description": "I expect same response , dedication of work from invenzolab's for my future plan.... Big thankyou and lots of wishes to Rajbhai , Ishan bhai, Akram bhai, Pavan bhai and entire invenzolab's team.",
            "image": "home/testimonial-photos/img-1.webp",
            "rating": 4
        },
        {
            "id": 2,
            "designation": "CEO, A2Z Hub",
            "name": "Nirav Velani ",
            "description": "Also the support provided by the team members at Invenzo Labs is exceptional. Their expertise and dedication are evident in their willingness to go above and beyond to address any challenges that arise during the project lifecycle. The level of cooperation and coordination within the team makes working with them a seamless experience.",
            "image": "home/testimonial-photos/img-2.webp",
            "rating": 3
        },
        {
            "id": 3,
            "designation": "CEO, Dr Store Healthcare Services Pvt. Ltd",
            "name": "Neeraj Katare",
            "description": "Invenzo Labs'  approach to task completion ensures efficient and timely delivery without compromising on quality. Their ability to adapt to changing requirements and priorities is commendable, resulting in successful project outcomes every time.",
            "image": "home/testimonial-photos/img-3.webp",
            "rating": 5
        },
        {
            "id": 4,
            "designation": "CEO, DCT Logistics Company, Myanmar",
            "name": "Andy Wai",
            "description": "Invenzo has provided DCT with a Technology platform for Courier Service. We faced problems but Invenzo was always there to help whenever needed. The IT Support & Customer Service they have given us was a great help in building our courier service. We are proud to say, through invenzo help, we are one of the few companies in Myanmar to fully utilize Technology Platform for Courier Operation Service.",
            "image": "home/testimonial-photos/img-4.webp",
            "rating": 4
        },
        {
            "id": 5,
            "designation": "CEO, Technova Oman",
            "name": "Balasubramanian Ramadasan",
            "description": "A very competent, responsive and professional team”. I am very happy with the experience till date. I wish Invenzo team continued success.",
            "image": "home/testimonial-photos/img-5.webp",
            "rating": 3
        },
        {
            "id": 6,
            "designation": "CEO, New World Mart",
            "name": "Aung Kyaw Zin",
            "description": "I am grateful to have met Mr. Raj Gopal and Mr. Prateek Mukherjee. They have been a great help and provided on the solution that we asked for. It was my pleasure to work with them. Izoleap has helped our business to be one step ahead of our competitors. I highly recommend them, and I can only wish for them to have more success.",
            "image": "home/testimonial-photos/img-6.webp",
            "rating": 2
        },
    ]

    const breakpoints = {
        "1720": {
            slidesPerView: 3,
            spaceBetween: 28,
            grid: { row: 3 },
            modules: [Grid, Pagination]
        },
        "1400": {
            slidesPerView: 2,
            spaceBetween: 28,
        },
        "1025": {
            slidesPerView: 2,
            spaceBetween: 28,
        },
        "768": {
            slidesPerView: 1,
        },
        "500 ": {
            slidesPerView: 1,
            spaceBetween: 20,
        },
        "360": {
            slidesPerView: 1,
            spaceBetween: 12,
        },
    };

    const targetRef = useRef<HTMLDivElement | null>(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
    });

    const smoothProgress = useSpring(scrollYProgress, { mass: 0.1 })

    const y = useTransform(smoothProgress, value => {
        return value * -(-50 - 5);
    });

    const x = useTransform(scrollYProgress, [0, 1], ["1%", "-65%"]);

    const { shown } = useYscroll({})

    return (
        <section ref={targetRef} className="relative">
            {shown &&
                <div className="py-10 xs:pb-0 xs:mb-0 mb-10 overflow-hidden">
                    <div className="flex-none w-full text-4xl font-extrabold p-2 lg:p-5 pt-2 text-center text-black mb-14 xs:mb-5 sm:mb-0">
                        <div className='text-center text-neutral-900 text-5xl sm:text-4xl sm:px-10 xs:text-2xl font-bold leading-[45px]'>{title}</div>
                    </div>
                    <div className='xs:hidden sm:hidden'>
                        {/* <Carousel
                    autoplay={false}
                    loop={false}
                    className={"testi-home"}
                    buttonClassName={""}
                    spaceBetween={32}
                    swiperNav={true}
                    buttonPosition={"inside"}
                    centeredSlides={false}
                    pagination={true}
                    breakpoints={breakpoints}
                > */}
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={0}
                            grid={{ rows: 2, }}
                            pagination={{ clickable: true, }}
                            modules={[Grid, Pagination]}
                            className="three-row-swiper"
                        >
                            <div className="">
                                {testimonial.map((card: any, index: any) => (
                                    <SwiperSlide key={index} virtualIndex={index} className="">
                                        <div key={index + 'testimonial-list'} className='w-full m-5 p-5 lg:h-[520px] xl:h-[440px] bg-[#611F6910] border' ><TestimonialTemplate res={card} /></div>
                                    </SwiperSlide>
                                ))}
                            </div>
                        </Swiper>
                        {/* </Carousel> */}
                    </div>

                    <div className="hidden xs:block sm:block pb-10 sm:pb-14 mb-10 overflow-hidden">

                        <Carousel
                            autoplay={true}
                            loop={true}
                            className={"testi-home"}
                            buttonClassName={""}
                            spaceBetween={32}
                            swiperNav={true}
                            buttonPosition={"inside"}
                            centeredSlides={false}
                            pagination={true}
                            breakpoints={breakpoints}
                        >
                            <div className="">
                                {testimonial.map((card: any, index: any) => (
                                    <SwiperSlide key={index} virtualIndex={index} className="">
                                        <div key={index + 'testimonial-list'} className='w-full m-5 xs:m-0 p-5 xs:h-full sm:h-full lg:h-[520px] xl:h-[430px] bg-[#611F6910] border' ><TestimonialTemplate res={card} /></div>
                                    </SwiperSlide>
                                ))}
                            </div>
                        </Carousel>
                    </div>

                </div>
            }
        </section>
    )
}
export default Testimonials
const TestimonialTemplate = ({ res }: any) => {
    return (
        <div className="flex gap-x-28 gap-y-14 lg:gap-x-12 h-full">
            <div className="flex flex-col gap-y-8">
                <div className=''>
                    {getIcon({ iconList: commonIcons, iconName: 'TestiQuateIcon' })}
                </div>
                <div className='text-lg xs:text-base font-medium pl-0 text-left sm:px-3'>"{res.description}"</div>
                <div className='flex gap-2 items-center mt-auto'>
                    <Image className=' rounded-full' src={`/images/${res?.image}`} alt="Invenzo Testimonial" width="50" height="50" />
                    <div className='mt-auto pl-5 text-left'>
                        <div className="text-[21px] font-semibold">{res.name}</div>
                        <span className='text-base font-normal'>{res.designation}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

