import Image from 'next/image'
import React from 'react'

function Awards({ homePage }: any) {
  return (
    <div className="flex flex-wrap xs:flex-nowrap sm:flex-nowrap gap-10 xs:gap-3 sm:gap-3 xs:mt-5 justify-center text-center">
      <div className="col-span-1 ">
        <Image src={'/images/home/awards/award-2.webp'} alt="Technical Partner Nasscom" width="200" height="250" className='h-full w-[200px]' />
      </div>
      <div className="col-span-1" >
        <Image src={'/images/home/awards/award-3.webp'} alt="Technical Partner TechBharath" width="155" height="155" className='h-full w-[200px]' />
      </div>
      <div className="col-span-1">
        <Image src={'/images/home/awards/award-1.webp'} alt="BAC Insider" width="155" height="155" className='h-full w-[200px]' />
      </div>
    </div>
  )
}

export default Awards