"use client"
import Container from '@/ui/container'
import React, { useRef, useState } from 'react'
import ContentBoxText from './content-box-text'
import { Swiper, SwiperSlide } from 'swiper/react';
import Carousel from '@/ui/carousel/carousel';
import { getIcon } from '@/lib/get-icon';
import commonIcons from '../icons/common-icons';
import Image from 'next/image';
import useYscroll from '../common/functions';
import YouTubeVideo from '../modal/youtubeVideo';

//import ReactPlayer from 'react-player';
import dynamic from 'next/dynamic';
const ReactPlayer = dynamic(() => import("react-player"), {
  loading: () => <p>Loading...</p>,
});


const breakpoints = {
    "1720": {
        slidesPerView: 5,
        spaceBetween: 28,
    },
    "1400": {
        slidesPerView: 5,
        spaceBetween: 28,
    },
    "1025": {
        slidesPerView: 5,
        spaceBetween: 28,
    },
    "768": {
        slidesPerView: 3,
    },
    "500 ": {
        slidesPerView: 2,
        spaceBetween: 20,
        pagination:false,
    },
    "360": {
        slidesPerView: 2,
        spaceBetween: 12,
        pagination:false,
    },
};

function CustomerSays({ props }: any) {
    const boxBg = props?.[0]?.boxBg;

    if (typeof document !== 'undefined') {
        document.addEventListener('DOMContentLoaded', function () {
            const videoElement = document.querySelector('video')!;
            const isInViewport = function (element: HTMLVideoElement) {
                const rect = element.getBoundingClientRect();
                return rect.top >= 0 && rect.bottom <= window.innerHeight;
            };
            const handleVideoState = function () {
                if (isInViewport(videoElement) !== null) {
                    videoElement.play();
                } else {
                    videoElement.pause();
                }
            };
            // Initial check
            handleVideoState();
            // Check on scroll
            window.addEventListener('scroll', handleVideoState);
        });
    }

    const fadeUpAni = {
        initial: { opacity: 0, y: 100 }, animate: (i: number) => ({ opacity: 1, y: 0, transition: { delay: 0.05 * i } })
    }
    const [isPlaying, setIsPlaying] = useState(null);

    const handlePause = () => {
        setIsPlaying(null)
    }

    const { shown } = useYscroll({})

    const [showModal, setShowModal] = useState(false);
    const [showVideo, setShowVideo] = useState("");

    const getVideo = (e:any) => {
        setShowVideo(e)
    }
    
    return (
        <>
            {shown &&
                <Container className="max-w-full px-0 xs:p-0 mt-0 mb-10 xs:mt-0 xs:mb-20 relative xl:px-[90px]">
                    <div className="flex justify-end p-[20px] gap-10 absolute bottom-0 sm:bottom-auto sm:justify-between sm:px-0 right-20 sm:left-0 sm:top-[26%] sm:w-full  z-10 xs:-bottom-16 xs:left-0 xs:w-full xs:justify-between">
                        <div onClick={handlePause} className="review_swiper_prev border border-[#611F69] rounded-full w-10 xs:w-8 h-10 xs:h-8 flex justify-center items-center cursor-pointer bg-white" style={{ borderColor: '#611F69' }}>{getIcon({ iconList: commonIcons, iconName: "CarouselArrowIcon", className: "xs:w-3 xs:h-3" })}</div>

                        <div onClick={handlePause} className="review_swiper_next border border-[#611F69] rounded-full w-10 xs:w-8 h-10 xs:h-8 flex justify-center items-center cursor-pointer bg-white rotate-180" style={{ transform: "rotateY(180deg)", borderColor: '#611F69' }}>{getIcon({ iconList: commonIcons, iconName: "CarouselArrowIcon", className: "xs:w-3 xs:h-3" })}</div>
                    </div>
                    <Carousel
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                            reverseDirection: false,
                          }}
                        loop={true}
                        className={`customer-says lg:h-[700px] sm:h-auto mx-0 fullWidth pagination-center col-span-12 self-end xs:h-full`}
                        buttonClassName={""}
                        spaceBetween={32}
                        swiperNav={false}
                        buttonPosition={"outside"}
                        centeredSlides={false}
                        pagination={true}
                        navigation={{
                            prevEl: ".review_swiper_prev",
                            nextEl: ".review_swiper_next",
                        }}
                    //   breakpoints={breakpoints}
                    >
                        <Swiper className="mySwiper" onSlideChange={() => { setIsPlaying(null); }} autoplay={false} watchSlidesProgress={true} >
                            {props?.map((props: any, index: number) =>
                                <SwiperSlide key={index} virtualIndex={index} className="">

                                    <div className="video-slider bg-white grid xs:grid-cols-1 lg:grid-cols-2 py-10 xs:p-0 gap-5 lg:gap-10 xl:gap-16">
                                        <div className="col-span-1 relative">
                                            <div className={`w-full h-full before:bg-[#ffffff] lg:p-10 p-2"} before:bg-[${boxBg}] before:absolute before:top-0 before:w-2/4 before:h-full before:z-0 before:right-0`} style={{ backgroundColor: boxBg }}>
                                                <div className='flex xs:flex-wrap w-full h-full bg-[#0B74B8] relative z-10'>

                                                    <div className='w-2/4 xs:w-full'>
                                                        <Image src={`/images/${props?.image}`} alt='Invenzo Video Testimonial' width={100} height={500} className='object-cover h-full w-full' />
                                                    </div>

                                                    <div className='w-[60%] xs:w-full flex flex-col justify-center items-start ml-2 lg:mx-3 xl:ml-5'>
                                                        <div className='flex items-center'>
                                                            <span onClick={() => setShowModal(true)} className='cursor-pointer' >
                                                                <span onClick={(e) => getVideo(props?.id)}>{getIcon({ iconList: commonIcons, iconName: "PlayIcon" })}</span>
                                                            </span>
                                                            <Image src={`/images/${props?.logo}`} alt='Invenzo Video Testimonial' width={434} height={130} className='w-auto h-10 lg:h-14 xl:h-16' />
                                                        </div>
                                                        {props?.tag && <div className='w-full pl-5 flex gap-5 xs:gap-2 flex-wrap my-5 xs:my-3'>
                                                            {props?.tag?.map((name: any, index: any) => (
                                                                <div key={index + 'solution_pages_name'} className="h-9 px-[21px] bg-pink-50 rounded-[5px] border border-neutral-900 justify-start items-center gap-4 inline-flex">
                                                                    <div className="text-neutral-900 text-xs font-bold uppercase leading-tight">{name?.name}</div>
                                                                </div>
                                                            ))}
                                                        </div>}
                                                    </div>

                                                    {/* <ReactPlayer
                                            key={props.id}
                                            url={props.video}
                                            controls={true}
                                            onPlay={() => { setIsPlaying(props.id); }}
                                            width={"100%"}
                                            // height={"500px"}
                                            playing={isPlaying === props.id}
                                            class={"react-player !h-[500px] xs:!h-[140px]"}
                                            onPause={handlePause}
                                        /> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-span-1 lg:pr-0 xl:pr-10 xs:pr-0`}>
                                            <ContentBoxText props={props} />
                                        </div>
                                    </div>



                                </SwiperSlide>
                            )}
                        </Swiper>
                    </Carousel>

                    <YouTubeVideo isVisible={showModal} onClose={() => setShowModal(false)} >
                        <ReactPlayer width="100%" height="100%" playing controls url={`${props?.[showVideo]?.video}`} />
                    </YouTubeVideo>

                </Container>
            }
        </>

    )
}

export default CustomerSays
