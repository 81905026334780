"use client"
import Container from '@/ui/container'
import Image from 'next/image'
import React from 'react'
import useYscroll from '../common/functions';

export default function CrmLeft() {
  const { shown } = useYscroll({})
  // const parallax = useParallax<HTMLDivElement>({
  //   rotate: [0, 360, 'easeInOut'],
  // });
  // const parallaxTwo = useParallax<HTMLDivElement>({
  //   scale: [0.5, 1, 'easeInQuad'],
  // });

  return (
    <Container className='xs:h-auto xs:pb-14 xs:relative'>
      {shown &&
        <div className='absolute xs:relative sm:static xs:top-9 w-full h-full xs:h-auto left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 xs:translate-y-0 sm:translate-y-0 sm:translate-x-0 flex justify-center items-center'>
          {/* <span><Image src={'/images/home/crm.png'} alt="CRM logo" width={235} height={235} /></span>*/}
          <span className='absolute bg-[url("/images/home/crm.webp")] bg-center bg-cover flex justify-center items-center text-wrap p-5 xs:p-10 text-center text-white w-[200px] h-[200px] xs:w-[120px] xs:h-[120px] text-[22px] xs:text-sm font-bold '>INVENZO PRODUCT SUITE</span>
          <span className="flex justify-center items-center w-full xs:h-auto"><Image src={'/images/home/crm-group.svg'} alt="CRM Group" width={235} height={235} className="w-[550px] h-auto" /></span>
        </div>
      }
    </Container>
  )
}
